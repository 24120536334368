$min-sm: 768px;
$min-md: 992px;
$min-lg: 1200px;

/* Card List */
.section.card-list {
  background: #edf2f8;
  padding: 4em 0;
}

.section.card-list .col-12.buckets {
  display: grid;
  gap: 30px;

  @media(min-width: $min-md) {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
  }
}

.card {
  background: #fff;
  border-radius: 10px;
  border: 0;
  box-shadow: 1.1px 1.7px 2.4px rgba(0, 0, 0, 0.02),
  2.7px 4px 6.3px rgba(0, 0, 0, 0.028),
  5.2px 7.6px 12.2px rgba(0, 0, 0, 0.035),
  9.7px 14.1px 21.8px rgba(0, 0, 0, 0.042),
  19.2px 27.8px 39px rgba(0, 0, 0, 0.05),
  42px 61px 80px rgba(0, 0, 0, 0.07);
  text-align: center;
  padding: 15px;

  @media(min-width: $min-md) {
    padding: 40px;
  }
}

.card--wrapper a {
  color: #000;
}

.card > .fa-duotone {
  font-size: 45px;
  color: #cc0033;
}

i.fa-duotone.fa-circle-arrow-right {
  font-size: 32px;
  color: #285d9b;
}


/* App List */

.section.apps {
  padding: 3em 0;

  .category-list {
    display: grid;
    gap: 30px;
    @media(min-width: $min-md) {
      grid-template-columns: 1fr 1fr;
      gap: 30px 60px;
    }
  }
}


.section-summary {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.section-summary h2 {
  font-weight: 600;
  color: #cc0033;
}

.category-pill {
  display: grid;
  grid-template-columns: 1fr 50px;
  gap: 15px;
  align-items: center;
  padding: 15px 20px;
  background: #f7f7f7;
  border-radius: 12px;
}

.category-pill .icon i {
  font-size: 30px;
}

.category-pill h4 {
  font-weight: 600;
  color: #326291;
}

.category-pill p {
  font-size: 14px;
}

.category-pill .go i.fa-duotone {
  font-size: 32px;
  color: #285d9b;
}

.section-summary {
  margin-bottom: 60px;
}

/* WP Button */
.wp-block-buttons .wp-block-button__link {
  background: #cc0033;
  color: #FFF !important;
  border-radius: 4px;
  padding: 10px 25px;
}
footer {
  padding: 0;
  > .container:nth-child(1) {
    padding: 2em;
  }
}


.main-footer {
  background: hsl(0, 0%, 12%);
  padding: 4em 0;
  color: #FFF;

  /* Footer Widget Title */
  h3 {
    font-size: 1rem;
    letter-spacing: 1px;
    font-weight: 500;
    color: $color-tertiary !important;
    margin-bottom: 15px;
    margin-top: 0;
    padding: 0;
  }

  h3, h4, h5, p, li, a {
    font-weight: 100;
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style-type: none;
    padding: 0;
    a {
      &:hover {
        color: $color-tertiary;
      }
      &:hover:after {
        padding: 0 0 0 10px;
      }
    }
  }

  a {
    color: #ededed;
  }

  p {
    color: hsl(0, 0%, 90%);
    font-weight: 300;
    font-size: 1rem;
    span {
      color: #9c9c9c;
    }
  }

  span.post-date {
    font-size: 0.8em;
    color: #9c9c9c;
    display: block;
    margin-bottom: 11px;
  }

  .menu-item-has-children {
    &:hover > a:after {
      transform: rotate(0);
    }

    & > a:after {
      content: " " !important;
    }
  }

  .sub-menu {
    position: relative !important;
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    background: none;
    border: none;
    left: -8px;
    margin: 0;
    padding-left: 15px;
    a:before {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: "\f3bf";
      font-family: 'Font Awesome 5 Free',sans-serif;
      font-size: 12px;
      transform: rotate(90deg);
      font-weight: 900;
      line-height: inherit;
      vertical-align: middle;
      padding: 1em 0.3em 0 0;
    }
  }

  .contact-info {
    margin-top: 10px;
  }

  /* Tribe Events */
  .tribe-events-list-widget {
    .tribe-event-title {
      font-size: 1em;
    }
    .tribe-event-duration {
      color: #9c9c9c;
      font-size: 0.8em;
    }
    .tribe-list-widget > li {
      padding: 0;
    }
    .tribe-events-widget-link a {
      background-color: $color-primary;
      font-weight: 100;
    }
  }

  /* Footer Rutgers Logo */
  .brand {
    color: #FFF;
    text-align: center;

    img {
      width: 100%;   
    }

    .logo-rutgers--noshield {
      width: 100%;
      max-width: 200px;
    }

    h4 {
      font-weight: 100;
      font-size: 1em;
      margin-top: 5px;
    }
  }

  .widget_nav_menu li {
    padding-bottom: 5px;
    &:last-child {
      padding-bottom: 0;
    }
  }

  /** Social Media Links*/

  .social-media {
    justify-content: flex-start;
  
    a {
      margin-right: 1em;
    }
    a:hover {
      color: #fc0;
    }
  }

  .container {
    padding: 0;
  }
  
}

/* Lower Footer with Policy */
.sub-footer {
  background: #1f1f1f;
  color: #666;
  padding: 15px 0;
  font-size: 1em;
  @media(min-width: breakpoint-min(md)) {
    font-size: 0.8em;
  }
  a {
    color: #fff;
    text-decoration: underline;
    &:hover, &:focus, &:active {
      color: $color-tertiary;
    }
  }

  p {
    font-size: 1rem;
    margin: 0;
    font-weight: 300;
    color: hsl(0, 0%, 75%)
  }

  > div {
    text-align: center;
    border-top: 1px solid hsla(0,0%,100%,.05);
    padding: 2rem 0;
  }
}

@media (max-width: 768px) {
  .main-footer .container .row {
    margin-right: 0;
    margin-left: 0;
  }
}
/*----------------------------/
   Section Header
----------------------------*/

.pre-header {
  background: darken($color-primary, 5) !important;
  color: #FFF;
  padding: 4px;

  ul {
    margin: 0;
    padding: 0;
    display: inherit;
  }

  li {
    display: inline-block
  }

  a {
    color: #FFF;
    font-weight: 100;
    font-size: 0.9em;
    margin-right: 1em;
    display: none;

    &:hover, &:focus {
      color: #fc0;
    }

    @media(min-width: breakpoint-min(sm)) {
      display: inline-block;
    }
  }
}

body > header {
  background: #FFF;
  color: #FFF;
  text-align: center;
  position: relative;
  z-index: 50;

  @media(min-width: breakpoint-min(md)) {
    text-align: left;
  }

  a {
    color: $color-primary;
    transition: all .15s ease-in-out;

    &:hover {
      color: $color-primary;
    }
  }

  > .container:nth-child(1), > .container:nth-child(2) { //Header
    padding: 1em 0;
    position: relative;
    z-index: 30;
    @media(min-width: breakpoint-min(lg)) {
      padding: 2em 0;
    }
  }

  > .navigation-bar { //Navigation container
    padding: 0;
    position: relative;
    z-index: 25;

    @media(min-width: breakpoint-min(md)) {
      background: #333;
      padding: 0 1em;
    }

    .container {
      padding: 0;
      @media(min-width: breakpoint-min(md)) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

  }

  li.active.menu-item > a,
  li.menu-item:hover > a,
  li.menu-item:focus > a {
    color: #EBB600;
  }
}

h2 span {
  display: inline-block;
  font-size: .6em;
  font-weight: 600;
  line-height: .7;
  padding-bottom: 6px;
  margin-bottom: 0;
  border-bottom: 3px solid $color-primary;
}

header .fa-search path {
  color: #FFF;
}

.logo {
  @media(min-width: breakpoint-min(md)) {
    display: flex;
    align-items: center;
  }

  h2 {
    display: block;
    font-size: 1.6em;
    width: auto;
    margin: 1em 0;
    font-weight: 300;
    @media(min-width: breakpoint-min(sm)) {
      margin: 0;
      font-weight: 100;
    }

    @media(min-width: breakpoint-min(md)) {
      border-left: 2px solid $color-primary;
      display: inline-block;
      font-size: 2em;
      margin-bottom: 10.3px;
      margin-left: 15px;
      padding-left: 15px;
    }
  }

  img {
    display: inline-block;
    image-rendering: optimize-contrast;
    max-width: 250px;

    @media(min-width: breakpoint-min(md)) {
      max-width: 300px;
    }
  }

  > .logo-rutgers {
    width: 300px;

    svg {
      width: 100%;
    }
  }
}

//Header Navigation
.mobile-menu {
  background: #333;
  font-size: 2em;
  padding: 10px;
  position: relative;
  z-index: 27;
  @media(min-width: breakpoint-min(lg)) {
    display: none;
  }
}

nav.nav-primary {
  background: #404040;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -100%;
  transition: all .2s ease-in-out;
  width: 100%;
  z-index: 1200;
  color: #FFF;
  left: 0;
  text-align: left;
  @media(min-width: breakpoint-min(lg)) {
    background: #333;
    opacity: 1;
    position: relative;
    visibility: visible;
    display: inline-block;
    top: 0;
  }

  .nav {
    display: block;
    @media(max-width: breakpoint-max(md)) {
      font-size: 11px;
    }
    @media(min-width: breakpoint-min(lg)) {
      display: inline-flex;
    }
  }

  ul {
    li {
      display: block;
      font-size: 1.6em;
      @media(min-width: breakpoint-min(lg)) {
        font-size: 1em;
      }

      a {
        display: block;
        color: #FFF;
        font-weight: 100;
        padding: 1em 5%;
        @media(min-width: breakpoint-min(lg)) {
          padding: 1em 0.8em;
        }
      }
    }
  }
}

.nav-primary.is-active {
  @media(max-width: $mobile-menu-max) {
    top: 100% !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}

header nav > ul > li:first-child {
  a {
    @media(min-width: breakpoint-min(lg)) {
      padding-left: 0 !important;
      margin-left: -15px;
    }
  }
}

.mobile-overlay.is-active {
  display: block;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  width: 100%;
  position: fixed;
  @media(min-width: breakpoint-min(lg)) {
    display: none;
  }
}

.menu-item-has-children {
  position: relative;

  > a:after { //dropdown chevron
    display: none;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    transition: all .2s ease-in-out;
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    font-weight: 900;
    line-height: inherit;
    vertical-align: baseline;
    padding: 0 6px 0 6px;

    @media(min-width: breakpoint-min(lg)) {
      display: inline-block;
      content: "\f107";
      float: none;
    }
  }

  //Space for Expand Button
  .menu-item-has-children > a {
    margin-right: 55px;

    @media(min-width: breakpoint-min(lg)) {
      margin: 0;
    }
  }

  span.mobile-expand {
    position: absolute;
    width: 55px;
    top: 0;
    right: 0;
    padding: 1em 20px;
    @media(min-width: breakpoint-min(lg)) {
      display: none;
    }
  }

  > a.focused {
    background: #272727;
  }

  &:hover > a:after {
    @media(min-width: breakpoint-min(lg)) {
      transform: rotate(180deg);
    }
  }
}

header .sub-menu {
  font-size: 0.6em;
  padding: 0;
  background: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  display: none;


  &.is-expanded {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  @media(min-width: breakpoint-min(lg)) {
    background: #333;
    position: absolute;
    transform: translate3d(15px, 0, 0);
    transition: all 0.2s ease-in-out;
  }

  //Secondary Menu Items
  > .menu-item-has-children {
    a:after {
      @media(min-width: breakpoint-min(lg)) {
        transform: rotate(-90deg) !important;
      }

      &:hover {
        margin-left: 15px;
      }
    }

    > .sub-menu {
      border: none;
      @media(min-width: breakpoint-min(lg)) {
        display: none;
      }
    }

    &:hover > .sub-menu {
      @media(min-width: breakpoint-min(lg)) {
        left: 100%;
        display: block !important;
        top: 0;
      }

    }
  }

  a {
    padding: 1em;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  }

  header &:hover {
    @media(min-width: breakpoint-min(lg)) {
      background: #1b1b1b;
    }
  }

  header & a:hover {
    color: $color-tertiary;
  }

  @media(min-width: breakpoint-min(sm)) {
    margin: 0;
    background: rgba(0, 0, 0, 0.2);


    @media(min-width: breakpoint-min(lg)) {
      font-size: 1em;
      border-top: 3px solid #f3cd05;
      top: 100%;
      left: 0;
      min-width: 200px;
      max-width: 350px;
      background: #333;
    }

  }

  li.menu-item.menu-item-has-children {
    padding: 0;

    @media(min-width: breakpoint-min(lg)) {
      background: #2f2f2f;
    }

    &:hover {
      @media(min-width: breakpoint-min(lg)) {
        background: #1b1b1b;
      }
    }
  }



  ul li {
    margin: 0 !important;
    width: 100%;
    padding: 0 15px;
    @media(min-width: breakpoint-min(lg)) {
      padding: 0;
    }
  }
}

header .menu-item-has-children:hover > .sub-menu, header .sub-menu.focused {
  position: relative;

  @media(min-width: breakpoint-min(lg)) {
    display: block;
    opacity: 1;
    position: absolute;
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}

#menu-header-menu > li {
  border-bottom: 2px solid #383838;
}

#search-button, .nav-primary .menu-header-menu-container {
  display: inline-block;
}

#search-button {
  display: none;
  @media(min-width: breakpoint-min(lg)) {
    display: block;
    float: right;
    height: 100%;
    margin-right: -1em;
    padding: 1em 1.2em;
  }

}

.header-search-form {
  background: #f7f7f7;
  min-width: 275px;
  padding: 13px;
  position: absolute;
  right: -1em;
  top: 100%;
  transition: all .2s ease-in-out;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  z-index: 300;

  &.closed {
    opacity: 0;
    transform: translate3d(-30px, 0, 0);
    visibility: hidden;
  }

  .wpdreams_asl_container {
    z-index: 300;
  }
}

.header-banner {
  > .container {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.pre-header {
  background: darken($color-primary, 5) !important;
  color: #FFF;
  padding: 4px;

  // a {
  //   color: #FFF;
  //   font-weight: 100;
  //   font-size: 0.9em;
  //   margin-right: 1em;
  //   display: none;
  //   @media(min-width: breakpoint-min(sm)) {
  //     display: inline-block;
  //   }
  //   &:hover, &:focus {
  //     color: #fc0;
  //   }
  // }

  select {
    height: auto;
    max-height: none;
    width: 100%;
    @media(min-width: breakpoint-min(sm)) {
      display: none;
    }
  }
}

.social-media {
  display: none;
  @media(min-width: breakpoint-min(sm)) {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }

  a {
    display: inline-block;

    i {
      font-size: 1.2em;
    }
  }
}

//Expanded Addendums
.mobile-expand {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);

  &.is-expanded {
    .fa-plus:before {
      content: "\f068";
    }
  }
}
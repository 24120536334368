.wcs-global-alert {
  background: #fc0;
  padding:  1rem 0;

  .row .col-12 {
    display: block;
  }

  p {
    margin: 0;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
  &__content-wrap {
    display: block;

    .fa-bell {
      color: #FFF;
      font-size: 2em;
      margin-right: 15px;
      display: none;
    }
  }

  &__button {
    display: block;
    color: #333;
    background: #fff;
    padding: 9px 15px;
    border-radius: 3px;
    text-align: center;
    width: 300px;
    float: none;
    &:hover {
      text-decoration: none !important;
    }
  }
}

@media (min-width: 992px) {
  .wcs-global-alert {
    padding:  2rem 0;
  }

  .wcs-global-alert__content-wrap .fas.fa-bell {
    display: inline-block;
  }
  .wcs-global-alert__content-wrap {
    display: inline-flex;
    align-items: center;
    flex: 1;
  }
  .wcs-global-alert .row .col-12 {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .wcs-global-alert__button {
    width: auto;
    margin-left: 30px;
    min-width: 250px;
  }
}

@media (max-width: 992px) {
  .wcs-global-alert__button {
    max-width: 250px;
    width: 100%;
    font-size: 1.3rem;
    margin: 1rem auto 0;
  }
}

.wcs-accordion {
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin: 0;
  padding: 0;

  &__wrap {
    position: relative;
  }

  &__title {
    margin-top: 1rem;
  }

  &__trigger {
    position: relative;
    display: block;
    padding: 0.875rem 1rem;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.125);
    appearance: none;
    margin: 0;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
    line-height: 0;
    border-radius: 0.25rem;
    background: rgba(0, 0, 0, 0.03);
    transition: all 0.2s ease-in-out;
    color: #007fac;

    &:hover,
    &:focus,
    &:active {
      background: rgba(0, 0, 0, 0.06);
      cursor: pointer;
    }

    &::before {
      position: absolute;
      line-height: 0.75rem;
      top: 1rem;
      right: 1rem;
      display: block;
      content: "+";
      font-family: monospace, monospace;
      font-size: 2rem;
      font-weight: 400;
      transition: transform 0.3s;
    }

    &__label {
      line-height: 1.25rem;
    }

    &[aria-expanded="true"] {
      /*     background-color: hsla(0, 0%, 0%, .05); */
      border-radius: 0.25rem 0.25rem 0 0;

      &::before {
        transform: rotate(45deg);
        transform-origin: center;

        /*   content: "x"; */
      }
    }

  }

  &__content {
    margin: 0;
    color: #000;
    transition: height 0.5s;
    line-height: 1.75rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    &__container {
      padding: 1rem;
    }
  }

  [aria-hidden="true"],
  [hidden] {
    display: none;
  }

  [aria-hidden="false"] {
    display: block;
  }
}

// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.wpcf7-form label {
  width: 100%;
}

.wpcf7-form .row > div {
  margin-bottom: 15px;
}

.wpcf7 {
  .form-control {
    background: #eee;
    border-radius: 3px;
    border: none;
    padding: 10px 15px;
    &:focus {
      background: #d0d0d0;
    }
  }
  select {
    height: auto !important;
    min-height: 40px;
  }
}
.logo-rutgers {
  display: inline-block;
  width: 250px;
  @media(min-width: breakpoint-min(sm)) {
    width: 300px;
  }

  svg {
    width: 100%;
    max-height: 85px;
    height: auto;
  }
}

#search-toggle {
  color: #FFF;
}

footer {
  .logo-rutgers {
    @media(min-width: breakpoint-min(sm)) {
      width: 100%;
    }
  }
  svg {
    max-height: 60px !important;
  }
}
.cb-row {
  padding: 2em 0;
  &.color-2 { //Dark Gray
    background: $color-secondary;
    color: #FFF;
  }

  &.color-3 { //Light Gray
    background: $color-box-bg;
  }

  &.color-4 { //Rutgers Red
    background: $color-primary;
    color: #FFF;
  }
}

.cb-row-custom {
  @extend .row;
  > div.col {
    margin: 15px 0;
    @media(min-width: breakpoint-min(md)) {
      margin: 0;
    }
  }
}

//Row Columns
.col-container {
  margin-bottom: 15px;
  padding: 15px;
  height: 100%;

  /* Styles */
  &.color-2 { //Dark Gray
    background: $color-secondary;
    color: #FFF;
    a {
      color: #FFF;
      &:hover {
        color: $color-tertiary;
      }
    }
    p {
      font-weight: 100;
    }
  }

  &.color-3 { //Light Gray
    background: $color-box-bg;
  }

  &.color-4 { //Rutgers Red
    background: $color-primary;
    color: #FFF;
  }

  /* Content */
    img, iframe {
      max-width: 100%;
    }

  /* Widgets */
  h5 {
    font-size: 1rem
  }

  h5 + p {
    font-size: 0.8rem;
  }

}

.cta-container {
  background: $color-primary !important;
  font-size: 0.8em;
  font-weight: 100;
  display: flex;
  text-align: center;
  height: 100%;
  align-items: center;
  align-content: center;
  padding: 15px;
  h4 {
    width: 100%;
  }
}

//Twitter
twitterwidget {
  width: 100% !important;
}
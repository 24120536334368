.btn {
  padding: 8px 16px;
  &.btn-cta {
    font-size: 1.4em;
    padding: 15px 30px;
    color: $color-primary;
    background-color: #FFF;
    border: 0.15rem solid #FFF;
    &:hover {
      background: $color-primary;
      border: 0.15rem solid $color-primary;
      color: #FFFFFF;
      box-shadow: 0 0 0 0.15rem #FFF;
    }
    &:focus {
      box-shadow: 0 0 0 0.15rem #fff;
      border: 0.15rem solid $color-primary;
      background: $color-primary;
      color: #FFFFFF;
    }
    &:not(:disabled):not(.disabled):active {
      background-color: darken($color-primary, 10);
      border: 0.15rem solid darken($color-primary, 10);
      box-shadow: 0 0 0 0.15rem #fff;
      color: #FFFFFF;
    }
    &:not(:disabled):not(.disabled):active:focus {
      border: 0.15rem solid darken($color-primary, 10);
      box-shadow: 0 0 0 0.15rem #fff;
      color: #FFFFFF;
    }
  }
  &.btn-primary {
    background: $color-primary;
    border: 0;
    box-shadow: none;
    &:hover {
      background: lighten($color-primary, 10);
    }
    &:focus {
      background-color: lighten($color-primary, 10);
      color: #fff;
      box-shadow: 0 0 0 0.15rem $color-secondary;
    }
    &:not(:disabled):not(.disabled):active {
      background-color: darken($color-primary, 10);
    }
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.15rem $color-secondary;
    }
  }
  &.btn-secondary {
    background: $color-secondary;
    color: #fff;
    border: 0;
    box-shadow: none;
    &:hover {
      background: lighten($color-secondary, 10);
    }
    &:focus {
      background-color: lighten($color-secondary, 10);
      color: #fff;
      box-shadow: 0 0 0 0.15rem $color-primary;
    }
    &:not(:disabled):not(.disabled):active {
      background-color: darken($color-secondary, 10);
    }
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.15rem $color-primary;
    }
  }
  &.btn-transparent {
    background: transparent;
    border: 0.15rem solid #fff;
    color: #fff;
    box-shadow: none;
    &:hover {
      background: $color-primary;
      border: 0.15rem solid $color-primary;
    }
    &:focus {
      box-shadow: 0 0 0 0.15rem #fff;
      border: 0.15rem solid $color-primary;
      background: $color-primary;
    }
    &:not(:disabled):not(.disabled):active {
      background-color: darken($color-primary, 10);
      border: 0.15rem solid darken($color-primary, 10);
      box-shadow: 0 0 0 0.15rem #fff;
    }
    &:not(:disabled):not(.disabled):active:focus {
      // background: lighten($color-primary, 10);
      border: 0.15rem solid darken($color-primary, 10);
      box-shadow: 0 0 0 0.15rem #fff;
    }
  }
}

.nav-pills .nav-link.active,
.show>.nav-pills .nav-link {
  background: $color-primary;
}

.fa-events-icons-loading {
  .fa,
  .fa:before,
  .fa:after,
  .menu-item-has-children>a:after {
    visibility: hidden;
  }
}
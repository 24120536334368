/*
Ajax Search Lite/Pro (Search Bar)
 */

#ajaxsearchlite1 .probox, div[id*="ajaxsearchlite"] .probox {
  background: #333333;
}

div[id*='ajaxsearchliteres'].vertical .results .item:first-child {
  border-radius: 0;
}

div[id*="ajaxsearchliteres"].vertical {
  background: #333;
}

div[id*="ajaxsearchlite"] .probox div.prosettings, div[id*="ajaxsearchlitesettings"].searchsettings {
  background: #646464;
}

.header-banner {
  #ajaxsearchlite1 .probox, div[id*="ajaxsearchlite"] .probox {
    background: #FFF;
  }
  #ajaxsearchlite1 .probox .proinput input, div.asl_w .probox .proinput input {
    color: #000 !important;
  }
  div.asl_w .probox .proinput input::-webkit-input-placeholder {
    color: #333 !important;

  }
}

div[id*="ajaxsearchlite"] .probox .promagnifier {
  background: #cd0633 !important;
}

// Aside

aside ul {
  list-style-type: none;
  padding: 0;
}

aside {
  .post-date {
    display: block;
  }
  .widget_recent_entries {
    li {
      margin-bottom: 10px;
    }
  }
}

aside .wpdreams_asl_container {
  z-index: 1 !important;
}

.sidebar-custom, .custom-sidebar {
  section {
    h3 {
      margin-top: 15px;
      font-size: 1.5em;

    }
    &:first-child {
      h3 {
        margin-top: 0;
      }
    }
  }
}

.sidebar-custom {

  .widget_nav_menu {
    a {
      display: block;
    }
  }

  .menu-item-has-children {
    &:hover > a:after {
      transform: rotate(0);
    }

    & > a:after {
      content: " " !important;
    }
  }

  .sub-menu {
    position: relative !important;
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    background: none;
    border: none;
    left: -8px;
    margin: 0;
    padding-left: 15px;
    a:before {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: "\f3bf";
      font-family: 'Font Awesome 5 Free',sans-serif;
      font-size: 12px;
      transform: rotate(90deg);
      font-weight: 900;
      line-height: inherit;
      vertical-align: middle;
      padding: 1em 0.3em 0 0;
    }
  }
}
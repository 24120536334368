/*
============================================================
 Post Archive
============================================================
*/

.featured-image {
  margin-bottom: 1em;

  img {
    width: 100%;
    height: auto;
  }
}


//AddThis Share Section
.share-buttons {
  margin: 15px 0 10px;
}

/*
============================================================
  Rutgers People / Person CPT (rutgers-people)
============================================================
*/

/* Single */
.person-image {
  img {
    height: auto;
    width: 100%;
  }
}


.rutgers-people {
  h4 {
    display: inline-block;
  }
  .cta-people {
    @media(min-width: breakpoint-min(sm)) {
      margin-left: 5px;
    }
    display: inline-block;
    .fa-stack {
      font-size: 1em;
      margin-right: 10px;
    }
  }
}

.people-sidebar {
  background: #f7f7f7;
  padding: 1em;
}

.person-summary {
  margin-top: 1em;
  h5 {
    font-weight: 600;
  }
  h3 {
    margin: 0 0 0.8em;
    font-weight: 600;
  }
}

.person-bio {
  li {
    margin-bottom: 1em;
    p {
      margin: 0;
    }
  }
}

.figure-img, .wp-caption>img {
  margin-bottom: .5rem;
  line-height: 1;
  box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.3);
}

article.type-rutgers-people {
  .list-view {
    margin: 50px 0;
  }
  margin: 0;
  h1 {
    margin-bottom: 0;
  }
  h3.people-title {
    margin-top: 0;
    margin-bottom: 1em;
  }
}

/* Archive */
.rutgers-people, .post-type-archive-rutgers-people {

  h2.entry-title {
    line-height: 1em;
    margin: 0;
    a {
      color: #333;
      transition: all 0.2s ease-in-out;
      &:hover {

      }
    }
  }

  h3 {
    margin: 0 0 15px;
    color: #666;
    font-weight: 300;
    font-size: 1.414em;
    a {
      font-size: 0.8em;
      color: $color-primary;
    }
  }

}

.ru-person-image {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  @media(min-width: breakpoint-min(lg)) {
    margin: 0;
  }
}

.content {
  img {
    max-width: 100%;
  }
}
// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

//Colors
$color-primary: #cd0533;
$color-secondary: #333;
$color-active: #007fac;
$color-tertiary: #fc0;
$color-box-bg: #f7f7f7;

//Fonts
$font-primary: 'Open Sans', sans-serif;

$text-shadow: 2px 2px 1px rgba(0,0,0,0.2);


//Mobile Menu
$mobile-menu-max: 992px;
.post-card {
  background: #f7f7f7;
  padding: 1rem 1rem 2rem;
  height: 100%;

  &__title {
    margin-top: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }

  &-meta {
    margin-bottom: 1rem;
    &__time-posted {
      display: block;
      margin-bottom: 1.5rem;
      font-variant: all-small-caps;
      font-size: 1.25rem;
    }
    &__category-link {
      background: hsl(0, 0%, 13%);
      padding: .25rem .5rem;
      border-radius: 4px;
      font-size: .8rem;
      color: white;

      &:hover, &:active, &:focus {
        background-color: #c03;
        color: white;
      }
    }
  }

  &-excerpt {
    font-size: .9rem;
    padding-bottom: 1rem;

    .read-more-link {
      display: block;
      margin: 1rem 0 0;
      font-size: 1rem;
      bottom: 1rem;
      position: absolute;
      text-decoration: underline;
      color: #007099;
      &:focus {
        outline: 1px dashed currentcolor;
        outline-offset: 1px;
      }
    }
  }
}
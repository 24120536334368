@import "../../../rusites/assets/styles/main.scss";

// // Automatically injected Bower dependencies via wiredep (never manually edit this block)
// // bower:scss
 // endbower

// @import "common/global";
// @import "components/carousel";
// @import "components/buttons";
// @import "components/comments";
// @import "components/forms";
// @import "components/grid";
// @import "components/wp-classes";
// @import "components/logo";
// @import "components/widget-column"
// @import "layouts/header";
// @import "layouts/sidebar";
// @import "layouts/footer";
// @import "layouts/pages";
// @import "layouts/posts";
// @import "layouts/tinymce";
// @import "layouts/content-builder";

@import "blocks";

.single-documentation article {
  padding: 15px;
  @media(min-width: $min-md) {
    padding: 0;
  }
}

iframe {
  max-width: 100%;
}
.main {
  img {
    max-width: 100%;
    height: auto;
    padding: 8px 0;
  }
}

@media (min-width: 767px) {
  .banners.owl-carousel .banner-wrapper.item.custom-content {
    min-height: 350px;
  }
}

.content li {
  @media(max-width: 575px) {
    font-size: 1.5rem;
  }
}

.login-container {
  > * {
    display: inline-block;
  }

  a {
    font-size: 1.2em;
  }

  > div:nth-of-type(1) {
    a {
      font-weight: 600;
    }
  }

  a:not(.email-login) {
    border: 2px solid $color-primary;
  }


  a.email-login {
    padding-right: 20px;
    padding-left: 20px;
  }

}


.btn.btn-primary.btn-white {
  background: #fff;
  border: 1px solid $color-primary;
  box-shadow: none;
  color: $color-primary;

  &:hover {
    background: darken(#fff, 10);
  }

  &:focus {
    background-color: darken(#fff, 10);
    color: $color-primary;
    box-shadow: 0 0 0 0.15rem $color-primary;
  }

  &:not(:disabled):not(.disabled):active {
    background-color: darken(#fff, 10);
  }

  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.15rem $color-primary;
  }
}


@media (max-width: 991.98px) {
  .content .cta-wrapper {
    padding: 2em 1em;

    div.app-buttons-container {
      display: flex !important;
      margin-top: 1em !important;
    }
  }

  .banners.owl-carousel .banner-wrapper.item.custom-content {
    min-height: 150px;

    p {
      margin: 0;

      img.canvas-logo {
        width: 70%;
      }
    }
  }

  .header-row {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    margin-right: 0 !important;
    margin-left: 0 !important;

    .brand-col {
      padding-left: 16px;
      display: flex;
      align-items: center;

      .logo-rutgers {
        width: 100%;
      }

      h2 {
        margin: 0;
      }
    }

    .login-col {
      padding-left: 0;
      padding-right: 16px;

      .login-container {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-gap: 10px;

        > div {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;

          a {
            margin: 0 !important;
            font-size: 1.3em
          }
        }

        > span {
          display: none;
        }

      }

    }

  }
}

.news-item-card {
  background: #f7f7f7;
  height: 100%;

  padding: 1em;
  height: 100%;

  // transition: all .2s ease-in-out;
  // cursor: pointer;

  // &:hover {
  // 	box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
  // }

  .news-item-excerpt {
    font-size: .9em;
  }

  .read-more-link {
    display: block;
    margin: 1em 0 0;
    font-size: 1rem;
    position: aboslute;
    bottom: 16px;
  }

}


@media (max-width: 991.98px) {
  .news-item .col-12 {
    padding: 15px;
  }

  .header-row .brand-col h2 {
    margin: 0;
    border-left: 0;
    bottom: 6px;
    position: relative;
    font-size: 1.9em;
  }

}


html {
  font-size: 0.7em;
  font-family: $font-primary;

  @media(min-width: breakpoint-min(sm)) {
    font-size: 1em;
  }
}


.cta-wrapper h2 {
  font-size: 1.2em;
}

// @media (min-width: 768px){
// 	.logo {
// 		display: inherit;
// 		align-items: inherit;
// 	}
// }

// @media (min-width: 991.98px){
// 	div.logo {
// 			display: -ms-flexbox;
// 			display: flex;
// 			-ms-flex-align: center;
// 			align-items: center;
// 	}
// }
aside {
  .documentation-sidebar {

    h3 {
      margin-bottom: 1em;
    }

    > ul {
      > li {
        font-size: 1.2em;
        margin-bottom: .8em;

        > ul {
          margin-left: 16px;
          margin-top: 8px;
          font-size: .8em;
        }
      }
    }
  }

  .external-apps-sidebar {
    h3 {
      margin-bottom: 1em;
    }

    li {
      font-size: 1.2em;
      margin-bottom: .8em;

      &.active-sidebar-link {
        font-weight: 600;
      }
    }
  }

  .widget_nav_menu {
    h3 {
      margin-bottom: 1em;
    }

    li.menu-item {

      margin-bottom: .8em;

      &.active {

        &.menu-item-has-children {
          > a {
            border-left: 4px solid;
            padding-left: 4px;
            margin-left: -8px;
            border-color: #007fac;
            font-weight: 600;
          }
        }

        &:not(.menu-item-has-children) {
          border-left: 4px solid;
          padding-left: 4px;
          margin-left: -8px;
          border-color: #007fac;

          font-weight: 600;
        }
      }

      a {
        font-size: 1.2em;
        // font-weight: 600;

      }
    }

  }
}

.post-type-archive-documentation {
  .cb-row-custom > div.col {
    margin: 15px 0;
  }
}

.active-sidebar-link {
  border-left: 4px solid;
  padding-left: 4px;
  margin-left: -8px;
  border-color: #007fac;
  font-weight: 600;
}

.posts-navigation {
  .nav-links {
    display: flex;
    justify-content: space-between;
  }
}

// h6 starts at $base-font-size 
// headings grow from h6 to h1 by $heading-scale
$base-font-size: 1em;
$heading-scale: 1.200; // amount of px headings grow from h6 to h1
$f: $base-font-size / $heading-scale;

@for $i from 6 through 1 {
  h#{$i} {
    font-size: ($f * $heading-scale);
  }

  $f: ($f * $heading-scale)
}

.external-apps {

  &-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-gap: 16px;

    &-item {
      display: grid;
      grid-template-areas: "header" "image" "excerpt";
      grid-template-rows: auto 200px auto;
      grid-gap: 16px;

      &-header {
        grid-area: header;
        margin: 0;
      }

      &-image {
        grid-area: image;
        align-self: center;

        img.external-app-img.wp-post-image {
          max-width: 200px;
          height: auto;
        }

      }

      &-excerpt {
        grid-area: excerpt;
        margin: 0;
        align-self: center;
      }
    }
  }
}

@media (max-width: 576px) {
  .left {
    flex-direction: column-reverse
  }

  .main-footer {
    font-size: 1.3em;
  }
}

.external_apps {
  .featured-image img {
    max-width: 200px;
    width: auto;
  }
}


.widget_nav_menu {

  .menu-item-has-children {

    > a.focused {
      background: inherit;
    }

    &.active {
      ul {
        a {
          &:before {
            display: none;
          }
        }
      }
    }

    &:not(.active) {

      ul {
        display: none;
      }

    }
  }
}

.sub-footer p {
  color: #868686;
}

div.app-buttons-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(115px, 115px));
  grid-gap: 16px;

  img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .main-footer {
    .row {
      .col-12 {
        padding-bottom: 8px;

        section.widget {
          padding-top: 16px;
        }
      }
    }
  }
}

.cta-wrapper {
  color: #FFFFFF;
}

body.in-iframe {
  > header, footer, aside.sidebar {
    display: none;
  }

  main.main {
    max-width: 100%;
    flex: 1;
  }
}

.external-apps-navigation {
  margin-bottom: 3em;

  > span {
    float: left;
    border-left: 2px solid #FFF;
    background: #4e4e4e;
    color: #FFF;
    transition: all 0.2s ease-in-out;
    margin-bottom: 10px;

    @media(max-width: breakpoint-max(sm)) {
      float: left;
      border-left: 2px solid #fff;
      background: #4e4e4e;
      color: #fff;
      transition: all .2s ease-in-out;
      margin-bottom: 10px;
      width: 30px;
      height: 23px;
      text-align: center;
    }


    &:hover {
      background: $color-primary;
    }

    a {
      font-weight: 600;
      padding: 0 8px;
      color: #FFF;
      font-size: 1.2em;
    }

    &:last-child {
      border-right: 2px solid #FFF;
    }
  }
}

.external-app-grid {
  display: grid;
  grid-template-columns: 120px 1fr;

  .external-app-img-container {
    align-self: center;
  }

  .external-app-img {
    padding: 0;
  }

  grid-gap: 32px;
}

@media (max-width: 576px) {
  .external-app-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 16px;

    .external-app-img {
      width: auto;
      height: auto;
      max-width: 80px;
    }
  }
}


/** Mobile Menu Search **/
// Belong to layouts/_header.scss
#search-button {
  display: inline-block;
  height: 55px;
  background: transparent;
  border-radius: 3px;
  border: none;
  align-items: center;
  display: flex;
  font-weight: 200;
  border-top: 3px solid #313131;

  a {
    padding: 1em 5%;
    width: 100%;
    font-size: 1.1em;
    @media(max-width: breakpoint-max(sm)) {
      font-size: 1.6em;
    }

    > span {
      @media(min-width: breakpoint-min(lg)) {
        display: none;
      }
      @media(min-width: breakpoint-min(xl)) {
        display: inline-block;
      }
    }
  }

  i {
    font-size: 16px;
    margin-right: 5px;
    font-weight: normal;
  }

  @media(min-width: breakpoint-min(lg)) {
    font-size: 16px;
    display: block;
    float: right;
    height: 100%;
    margin-right: -1em;
    padding: 1em 1.2em;
    border-top: 0;

    i {
      font-size: 20px;
    }

    a {
      padding: 1em .8em;
    }
  }
}

.header-search-form {
  background: #f7f7f7;
  min-width: 275px;
  padding: 13px;
  position: absolute;
  right: -1em;
  top: 100%;
  transition: all .2s ease-in-out;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  z-index: 300;

  @media(max-width: breakpoint-max(md)) {
    width: 100%;
    right: 0;
  }

  &.closed {
    opacity: 0;
    transform: translate3d(-30px, 0, 0);
    visibility: hidden;
  }

  .wpdreams_asl_container {
    z-index: 300;
  }
}

.mobile-menu {
  text-align: center;
}


.global-alert {
  background: #fc0;
  padding: 1em 0;


  .row .col-12 {
    display: block;
  }

  p {
    margin: 0;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  &--content {
    display: block;
    float: none;

    .fa-bell {
      color: #FFF;
      font-size: 2em;
      margin-right: 15px;
      display: none;
    }
  }

  &--button {
    display: block;
    color: #333;
    background: #fff;
    padding: 9px 15px;
    border-radius: 3px;
    text-align: center;
    width: 300px;
    margin: 15px auto 5px;
    float: none;

    &:hover {
      text-decoration: none !important;
    }
  }
}

//
@media (min-width: 992px) {
  .global-alert--content .fas.fa-bell {
    display: inline-block;
  }
  .global-alert--content {
    float: left;
    display: inline-flex;
  }
  .global-alert .row .col-12 {
    display: flex;
  }
  .global-alert--button {
    display: inline-block;
    width: auto;
    float: right;
    margin-left: 30px;
    min-width: 250px;
  }
}

@media (max-width: 992px) {
  .global-alert--button {
    max-width: 250px;
    width: 100%;
  }
}


#search-button a {
  padding: 1em 0 !important;
}

.search-app-form {
  padding: 15px;
  background: #dfdfdf;
  border-radius: 3px;
  position: sticky;
  top: 15px;

  .categories {
    display: grid;
    gap: 5px;

    label {
      display: grid;
      align-items: baseline;
      gap: 5px;
      grid-template-columns: 15px auto;
      width: 100%;
    }
  }

  form {
    display: grid;
    gap: 15px;
  }

  input[type=text], select {
    width: 100%;
    padding: 5px 15px;
  }

}

.exapp-term {
  gap: 5px 15px;
  display: flex;
  flex-wrap: wrap;

  a {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 10px;
    display: block;
    color: #00374b;
    background: #cdebfa;
    padding: 3px 15px;
    margin-bottom: 5px;
    border-radius: 30px;
  }
}

.doc-num {
  margin-top: 10px;
  background: #c6e0e9;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  margin-bottom: 7px;
  padding: 4px 0;
  font-weight: 600;
  border-radius: 13px;
}

.docs-list {
  padding: 0;

  li {
    margin-bottom: 12px;
    font-size: 1.1em;
    a {
      grid-template-columns: 10px 1fr;
      display: grid;
      align-items: baseline;
      line-height: 1.5;
      gap: 8px;
    }

  }

  .doc-title a {
    color: #333;
  }




}

.doc-header {
  border-bottom-color: #c03 !important;
  margin-bottom: 15px;
}

.menu-item-has-children > a:after, .main-footer .sub-menu a:before {
  font-family: 'Font Awesome 6 Pro', sans-serif;
}

.home {
  .header--text h2 {
    font-size: 1.2em;
    line-height: 1.6;
    font-weight: 600;
    max-width: 450px;
  }

  .header--content-wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;

    @media(min-width: $min-md) {
      gap: 60px;
      grid-template-columns: 1fr 1fr;
    }
  }

  .news-latest {
    background: rgba(0, 0, 0, 0.4);
    padding: 30px;
    border-radius: 8px;
    margin: 50px 0;

    h3 {
      color: #FFF;
      font-size: 1.2em;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-weight: 800;
    }
  }

  .news {
    display: grid;
    grid-template-columns: 35px 1fr;
    gap: 15px;
    align-items: center;
    color: #FFF;

    i.fa-duotone.fa-newspaper {
      font-size: 2em;
    }

    i.fa-duotone:after {
      color: #98c3ff;
    }

    h4 {
      font-size: 1.2em;
    }

    span {
      font-size: 0.8em;
      text-transform: uppercase;
      font-weight: 800;
    }
  }


  .news-wrapper {
    display: grid;

    > a {
      display: block;
      padding: 15px;
      border-radius: 8px;
      transition: background 0.2s ease-in-out;

    }

    > a:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  /* banner */
  .announcement--wrapper {
    background: rgba(27, 40, 78, 0.82);
    color: #fff;
    padding: 30px 0;

    @media(min-width: $min-md) {
        padding: 30px 0;
    }
  }


  .fa-duotone.fa-bullhorn {
    font-size: 2.5em;
  }

  .announcement--content-wrapper .content {
    display: grid;
    align-items: center;
    gap: 20px;
    @media(min-width: $min-md) {
      grid-template-columns: 80px 1fr;
    }
  }

  .announcement--content-icon {
    text-align: center;
    @media(min-width: $min-sm) {
      text-align: left;
    }
  }

  .announcement--content-wrapper .read-more {
    text-align: center;
    @media(min-width: $min-md) {
        text-align: right;
    }
  }

  .announcement--content-wrapper .read-more a {
    color: #000;
    background: #fff;
    padding: 10px 25px;
    display: inline-flex;
    font-size: 18px;
    border-radius: 5px;
    text-align: center;
    @media(min-width: $min-md) {
      text-align: right;
    }
  }

  .announcement--content-wrapper .read-more i {
    font-size: 22px;
    margin-left: 8px;
  }

  .announcement--content-wrapper {
    display: grid;
    align-items: center;

    @media(min-width: 768px) {
      grid-template-columns: 1fr 300px;
    }
  }

  .announcement--wrapper a {
    color: #fff;
  }

  .announcement--content span {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
  }

  @keyframes tilt-n-move-shaking {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    20% {
      transform: translate(5px, 5px) rotate(5deg);
    }
    40% {
      transform: translate(0, 0) rotate(0deg);
    }
    60% {
      transform: translate(-5px, 5px) rotate(-5deg);
    }
    80% {
      transform: translate(0, 0) rotate(0deg);
    }
    100% {
      transform: translate(0, 0) rotate(0deg);
    }
  }
}

.main .container {
  padding-left: 15px !important;
}

.main {
  padding: 0;
}

.canvas-logo {
  width: 100%;
}

.section.apps {
  background: #FFF;
}
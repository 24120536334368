.owl-carousel {
  visibility: hidden;
  &.owl-loaded {
    visibility: visible;
  }
}

.banners.owl-carousel {
  background: #222;
  display: flex;
  .banner-wrapper.item {
    display: flex;
    flex: 1;
    height: 100%;
    min-height: 300px;
    @media(min-width: breakpoint-min(sm)) {
      min-height: 500px;
    }
  }
}

.header-banner {
  width: 100%;
  display: flex;
  align-items: center;
}

.owl-stage {
  display: flex;
  .owl-item {
    display: flex;
    flex: 1;
  }
}

.banner-wrapper {
  display: none;
  .owl-item & {
    display: block;
  }
}

.comment-list {
  @extend .list-unstyled;
}
.comment-list ol {
  list-style: none;
}
.comment-form p {
  @extend .form-group;
}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
  @extend .form-control;
}
.comment-form input[type="submit"] {
  @extend .btn;
  @extend .btn-secondary;
}

#comments {
  .vcard {
    margin-bottom: 5px;
  }
}

#cancel-comment-reply-link {
  @extend .btn;
  @extend .btn-primary;
}

.comment.depth-2 {
  padding-left: 10px;
  border-left: 3px solid $color-primary;
}

.comment-metadata {
  time {
    font-size: 0.8em;
  }
}
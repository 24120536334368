html {
  font-size: 0.7em;
  font-family: $font-primary;
  @media(min-width: breakpoint-min(md)) {
    font-size: 1em;
  }
}

body {
  font-weight: 400;
  line-height: 1.45;
}

/*
.container {
  @media (min-width: 1450px) {
    width: 1400px;
    max-width: 1400px;
  }
}
*/

h1, h2, h3, h4, h5, h6, a, p, span, div, i, b, strong {
  font-family: $font-primary;
}

p {
  margin-bottom: 1em;
  font-size: 1.5rem;
  @media(min-width: breakpoint-min(sm)) {
    font-size: 1rem;
  }
}

a {
  color: $color-active;

  &:hover {
    text-decoration: none;
  }
}

h1, h2, h3, h4 {
  margin: 0 0 0.3em;
  font-weight: inherit;
  line-height: 1.2;
  font-family: $font-primary;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.227em;
  span {
    display: inline-block;
    font-size: .6em;
    font-weight: 600;
    line-height: .7;
    padding-bottom: 6px;
    margin-bottom: 0em;
    border-bottom: 3px solid #cd0533;
  }
}

h3 {
  font-size: 1.999em;
}

h4 {
  font-size: 1.414em;
}

small, .font_small {
  font-size: 0.707em;
}

article {
  margin-bottom: 30px;
}

.screen-reader-text:active, .screen-reader-text:focus, .sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  background: #FFF;
  padding: 15px;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
  z-index: 1000;
}
